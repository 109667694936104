import React, { useEffect, useState } from 'react'
import { gql, useQuery, DocumentNode } from '@apollo/client'
import * as styles from 'styles'
import styled from 'styled-components'
import FeaturedMarket from 'components/FeaturedMarket'
import { Header1 } from './Typography'
import { MarketRelation } from 'types/indexer'

interface ShareProps {
  id: string
  media: string
}

interface FeaturedMarketsProps {
  title: string
  useUrl?: string
  query: DocumentNode
}

const Container = styled.div`
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  padding-bottom: ${styles.PADDING_XL};
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    padding-bottom: ${styles.PADDING_XS};
  }
`

const MarketsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`

const HeaderText = styled(Header1)`
  margin-bottom: ${styles.PADDING_LG};
`

const buildPath = (isAuction: boolean, market: MarketRelation) =>
  isAuction
    ? `${process.env.NEXT_PUBLIC_BASE_URI}/thing/${market.thing.id}/auction`
    : `${process.env.NEXT_PUBLIC_BASE_URI}/store/${market.thing?.store?.id}`

const FeaturedMarkets = ({
  title,
  query,
  isAuction = false,
}: {
  title: string
  query: DocumentNode
  isAuction: boolean
}) => {
  const { loading, data } = useQuery(query)

  return (
    <Container>
      <HeaderText size="2em">{title}</HeaderText>
      <MarketsContainer>
        {data?.markets.map((market) => (
          <FeaturedMarket
            market={market}
            key={market.id}
            clickUri={buildPath(isAuction, market)}
          />
        ))}
      </MarketsContainer>
    </Container>
  )
}

export default FeaturedMarkets
