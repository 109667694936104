import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import * as styles from 'styles'
import * as paths from 'content/paths'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Twitter } from '@styled-icons/icomoon/Twitter'
import { Facebook } from '@styled-icons/boxicons-logos/Facebook'
import { Pinterest } from '@styled-icons/boxicons-logos/Pinterest'
import { Linkedin } from '@styled-icons/boxicons-logos/Linkedin'
import AssetLoader from './AssetLoader'
import { MarketRelation } from 'types/indexer'
import Link from 'next/link'

interface ShareProps {
  storeUri: string
  media?: string
}

const genUri = (str) => encodeURIComponent(str)

const socialShare = {
  twitter: (props: ShareProps) =>
    `https://twitter.com/intent/tweet?text=What%20a%20neat%20NFT%20store%20here&via=mintbase&url=${props.storeUri}`,

  facebook: (props: ShareProps) =>
    `https://www.facebook.com/sharer.php?u=${props.storeUri}`,

  pinterest: (props: ShareProps) =>
    `https://pinterest.com/pin/create/button/?url=${props.storeUri}&media=${props.media}`,

  linkedin: (props: ShareProps) =>
    `https://www.linkedin.com/shareArticle?mini=true&url=${props.storeUri}`,
}

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: ${({ theme }) => theme.DARK};
  margin-right: ${styles.PADDING_SM};
  flex: 1 1 23%;
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    flex: 1 1 48%;
    margin-right: ${styles.PADDING_XS};
    margin-bottom: ${styles.PADDING_XS};
    &:nth-child(even) {
      margin-right: 0;
    }
  }
`

const SocialContainer = styled.div`
  z-index: 1;
  display: none;
  position: absolute;
  top: 20px;
  right: 20px;
  color: ${({ theme }) => theme.OFF_WHITE};
  font-size: 1.5em;
`

const SocialButtons = styled.div`
  display: flex;
`

const AssetHolder = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
`

const ImageContainer = styled(motion.div)`
  display: flex;
  cursor: pointer;
  position: relative;

  &:hover ${AssetHolder} {
    filter: grayscale(100%) blur(2px) contrast(100%);
  }

  &:hover ${SocialContainer} {
    display: block;
  }
`

const IconContainer = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.ALWAYS_WHITE};
  margin-right: 10px;
  padding: 5px;
  border: 1px solid ${({ theme }) => theme.ALWAYS_WHITE};
  width: 20px;
  height: 20px;
  max-width: 100%;
  &:hover {
    border: 1px solid ${({ theme }) => theme.DARK1};
    color: ${({ theme }) => theme.DARK1};
  }

  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    flex-direction: column;
    width: 15px;
    height: 15px;
    margin-right: ${styles.PADDING_SM};
  }
`

const TwitterIcon = styled(Twitter)`
  width: 100%;
`

const FacebookIcon = styled(Facebook)`
  width: 100%;
`

const PinterestIcon = styled(Pinterest)`
  width: 100%;
`

const LinkedinIcon = styled(Linkedin)`
  width: 100%;
`

const FeaturedMarket = ({
  market,
  clickUri,
}: {
  market: MarketRelation
  clickUri: string
}) => {
  const router = useRouter()
  const [contract, setContract] = useState('')

  const [mint, setMint] = useState(null)

  useEffect(() => {
    if (market?.thing?.metaId) {
      // getMarket(market?.thing?.metaId)
      setContract(market?.thing?.store?.id)
    }
  }, [market])

  const storeUri = encodeURIComponent(clickUri)

  return (
    <Container>
      {market?.thing?.metadata && (
        <ImageContainer
          key={market.id}
          whileHover={{ scale: 1.03 }}
          transition={{ duration: 0.1 }}
        >
          <SocialContainer>
            <SocialButtons>
              <IconContainer
                href={socialShare.twitter({
                  storeUri,
                })}
                target="_blank"
              >
                <TwitterIcon></TwitterIcon>
              </IconContainer>
              <IconContainer
                href={socialShare.facebook({ storeUri })}
                target="_blank"
              >
                <FacebookIcon></FacebookIcon>
              </IconContainer>
              <IconContainer
                href={socialShare.pinterest({
                  storeUri,
                  media: market?.thing?.metadata?.media,
                })}
                target="_blank"
              >
                <PinterestIcon></PinterestIcon>
              </IconContainer>
              <IconContainer
                href={socialShare.linkedin({ storeUri })}
                target="_blank"
              >
                <LinkedinIcon></LinkedinIcon>
              </IconContainer>
            </SocialButtons>
          </SocialContainer>
          {
            <Link href={clickUri} passHref>
              <AssetHolder>
                <AssetLoader mint={market?.thing?.metadata} />
              </AssetHolder>
            </Link>
          }
        </ImageContainer>
      )}
    </Container>
  )
}

export default FeaturedMarket
