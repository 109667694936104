import { Tools } from '@styled-icons/entypo/Tools'
import * as paths from 'content/paths'
import Arweave from 'components/Arlogo'
import { StoreMallDirectory } from '@styled-icons/material-sharp/StoreMallDirectory'

export interface Fact {
  type: string
  main: string
  loud: string
  small: string | null
  path: string
}

export const costs: Fact[] = [
  {
    type: 'costs',
    main: 'Mint',
    loud: '$~0.0031',
    small: '0.001 N',
    path:
      'https://explorer.near.org/transactions/8GAohCWhBpC72LzU7LbLZ25x5V45dV77adQsyUfrEJQL',
  },
  {
    type: 'costs',
    main: 'Transfer',
    loud: '$~0.004',
    small: '0.001131 N',
    path:
      'https://explorer.near.org/transactions/7zMag5wMAM7aQm9DSSBiRBdDy5PXUYvmpiVoHroAtsAb',
  },
  {
    type: 'costs',
    main: 'List',
    loud: '$~0.0062',
    small: '0.002 N',
    path:
      'https://explorer.near.org/transactions/C36duqCx6zEYZUMDtfkdDQ3zRuCdqyXyVBu5e6ZiW69B',
  },
]

export const speed: Fact[] = [
  {
    type: 'speed',
    main: 'TPS',
    loud: '100k',
    small: null,
    path: 'https://explorer.near.org',
  },
  {
    type: 'speed',
    main: 'Blocktimes',
    loud: '~1 sec',
    small: null,
    path: 'https://explorer.near.org/',
  },

  {
    type: 'speed',
    main: 'Nodes',
    loud: '+60',
    small: null,
    path: 'https://explorer.near.org/nodes/validators',
  },
  {
    type: 'speed',
    main: 'Sharding',
    loud: 'Yes',
    small: null,
    path: 'https://near.org/papers/nightshade',
  },
]

export const store = {
  Icon: StoreMallDirectory,
  text: 'Your Store = Your Contract = Your Own Dapp',
  small: 'Add minters, build your own marketplace, do your thing.',
  path: paths.CONTRACT,
  buttonText: 'Get Started',
  isDark: true,
}

export const data = {
  Icon: Arweave,
  text: 'No one can change your data,',
  small: 'not us or even you...',
  path: paths.DATA,
  buttonText: 'Learn More',
  isDark: false,
}

export const code = {
  Icon: Tools,
  text: 'Anything we do on Mintbase, you can do with code',
  small: 'Mint, Transfer, Purchase, Get Owner...',
  path: paths.DEVELOPERS,
  buttonText: 'Get Started',
  isDark: true,
}
