import { useQuery } from '@apollo/client'
import * as paths from 'content/paths'
import useTranslation from 'next-translate/useTranslation'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { GET_AGGREGATES } from 'services/queries'
import styled from 'styled-components'

import { parseYactoToNear } from 'utils/numbers'
import * as styles from '../styles'
import { useApp, useDispatchApp } from './AppContext'
import { Button } from './Buttons'
import { Copy, DarkInput, Header1, HeaderThin } from './Typography'

const ThreeDThing = dynamic(() => import('components/canvas/ThreeDThing'), {
  ssr: false,
})

const Container = styled.div`
  overflow-y: hidden;
  width: 100%;
  height: 100%;
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    flex-direction: column;
    background-attachment: local;
  }
`

const Logo = styled(styles.LatoLight)`
  color: ${({ theme }) => theme.ALWAYS_WHITE};
  font-size: 3.3em;
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 3.1rem;
  font-weight: 100;

  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    font-size: 2.8rem;
  }
`

const Subtitle = styled(Copy)`
  color: ${({ theme }) => theme.ALWAYS_WHITE};
  margin-bottom: ${styles.PADDING_SM};
  font-size: 1.5rem;
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    font-size: 2em;
    padding-top: 0;
    padding-right: ${styles.PADDING_MD};
    padding-left: ${styles.PADDING_MD};
  }
`

const MainText = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-grow: 1;
  z-index: 1;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: ${styles.PADDING_SM};
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    text-align: center;
    font-size: 0.9em;
    justify-content: center;
    flex-grow: 1;
  }
`

export const WideDarkInput = styled(DarkInput)`
  display: flex;
  width: 300px;
`
const CanvasWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0;
  padding: 0;
  user-select: none;
  overflow: hidden;
`

const SideStats = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1;
  right: ${styles.PADDING_MD};
  top: ${styles.PADDING_MD};
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    font-size: 0.8em;
    flex-direction: row;
    right: ${styles.PADDING_MD};
    left: ${styles.PADDING_MD};
    flex-grow: 1;
    justify-content: space-between;
  }
`

const Buttons = styled.div`
  display: flex;
`

const OutlineButton = styled(Button)`
  display: flex;
  padding: ${styles.PADDING_SM};
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.ALWAYS_WHITE};
  color: ${styles.ALWAYS_WHITE};
  &:first-child {
    margin-right: ${styles.PADDING_SM};
  }
`

const Stat = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  margin-bottom: ${styles.PADDING_SM};
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    text-align: center;
  }
`
const StatHeader = styled(HeaderThin)`
  display: flex;
  font-size: 1.8rem;
  flex-direction: column;
  color: ${({ theme }) => theme.DARK2};
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    font-size: 1.5rem;
  }
`

const StatName = styled(Copy)`
  display: flex;
  font-size: 1em;
  color: ${({ theme }) => theme.DARK2};
  flex-direction: column;
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    font-size: 0.8rem;
  }
`

const HomeHeader = () => {
  const { account, colors } = useApp()
  const dispatch = useDispatchApp()
  const { t } = useTranslation('home')
  const mouse = useRef([0, 0])

  const router = useRouter()

  const [initalBlock, setInitialBlock] = useState(0)
  const [block, setBlock] = useState(0)
  const [blocks, setBlocks] = useState<number[]>([])
  const [endX, setEndX] = useState(0)

  const [stats, setStats] = useState([])

  const { loading, error, data } = useQuery(GET_AGGREGATES)

  useEffect(() => {
    if (data?.stores_aggregate) {
      setStats([
        {
          stat: data?.stores_aggregate.aggregate?.count,
          name: 'stores',
        },
        // {
        //   stat: data?.things_aggregate.aggregate?.count,
        //   name: 'things',
        // },
        {
          stat: parseYactoToNear(
            data?.offers_aggregate.aggregate?.sum?.price
          ).toFixed(1),
          name: 'Earned',
        },
        {
          stat: data?.tokens_aggregate.aggregate?.count,
          name: 'tokens',
        },
        {
          stat: data?.minters_aggregate.aggregate?.count,
          name: 'minters',
        },
        {
          stat: data?.lists_aggregate.aggregate?.count,
          name: 'listed',
        },
      ])
      return
    }
  }, [data])

  if (typeof window !== 'undefined') {
    useEffect(() => {
      setEndX(window.screen.width)
    }, [])
  }

  const onMouseMove = useCallback(({ clientX: x, clientY: y }) => {
    mouse.current = [x - window.innerWidth / 2, y - window.innerHeight / 2]
  }, [])

  return (
    <Container>
      <CanvasWrapper onMouseMove={onMouseMove}>
        <MainText>
          <Logo>{t`hero.title`}</Logo>
          <Subtitle top={styles.PADDING_MD}>{t`hero.subtitle`}</Subtitle>
          <Buttons>
            <Link href={paths.DEPLOY} passHref>
              <OutlineButton>{t`deploy-store`}</OutlineButton>
            </Link>
            <Link href={paths.MARKET} passHref>
              <OutlineButton>{t`explore-market`}</OutlineButton>
            </Link>
          </Buttons>
        </MainText>
        <SideStats>
          {stats.map(({ stat, name }) => (
            <Stat key={name}>
              <StatHeader>{stat}</StatHeader>
              <StatName>{name}</StatName>
            </Stat>
          ))}
        </SideStats>
        <ThreeDThing mouse={mouse} />
      </CanvasWrapper>
    </Container>
  )
}

export default HomeHeader
