import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Head from 'next/head'
import * as styles from 'styles'
import { Tools } from '@styled-icons/entypo/Tools'
import Arweave from 'components/Arlogo'
import Footer from 'components/Footer'
import Hero from 'components/Hero'
import FeaturedMarkets from 'components/FeaturedMarkets'
import FunFacts from 'components/FunFacts'
import { Header1 } from 'components/Typography'
import SocialButtons from 'components/SocialButtons'
import GoogleAnalytics from 'components/GoogleAnalytics'
import * as paths from 'content/paths'
import useTranslation from 'next-translate/useTranslation'
import LinkBox from 'components/LinkBox'
import { CallSplit } from '@styled-icons/material-sharp/CallSplit'
import { NaturePeople } from '@styled-icons/material-rounded/NaturePeople'
import { Code } from '@styled-icons/feather/Code'
import { StoreMallDirectory } from '@styled-icons/material-sharp/StoreMallDirectory'
import { costs, speed, data, code, store } from 'content/facts'
import BigQuote from 'components/BigQuote'
import SplitsInfo from 'components/SplitsInfo'
import { Button } from 'components/Buttons'
import Link from 'next/link'
import { GET_TOP_AUCTIONS, GET_TOP_STORES } from 'services/queries'

const fetcher = (url, token) =>
  fetch(url, {
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json', token }),
    credentials: 'same-origin',
  }).then((res) => res.json())

interface DispatchPayload {
  type: string
  payload: any
}

const HeadRow = styled.div`
  display: flex;
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    flex-direction: column;
  }
`

const Page = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${({ theme }) => theme.DARK};
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    font-size: 0.8em;
  }
`

const Boxes = styled.div`
  display: flex;
  padding: ${styles.PADDING_MD};
  flex-grow: 1;
  flex-wrap: wrap;
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    flex-direction: column;
    padding: ${styles.PADDING_SM};
  }
`

const Info = styled(Header1)`
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    display: none;
  }
`

const Bar = styled.div`
  padding: ${styles.PADDING_MD};
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    flex-direction: column;
    padding: ${styles.PADDING_XS};
  }
`

const Main = styled.div`
  display: flex;
  height: 570px;
  flex-grow: 1;
  background-color: ${({ theme }) => theme.DARK};

  position: relative;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.DARK2};
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    height: 70vh;
  }
`

const SocialContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  background-color: ${({ theme }) => theme.DARK};
  /* border-top: 1px solid ${styles.DARK3}; */
  /* padding: ${styles.PADDING_SM}; */
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    justify-content: center;
  }
`

const press = [
  {
    id: 'press-0',
    path:
      'https://www.nasdaq.com/articles/mintbase-raises-%241m-seed-round-to-bring-nfts-to-near-protocol-2020-11-18',
    img: '/home/nasdaq.jpg',
  },
  {
    id: 'best-pod',
    path:
      'https://anchor.fm/andrew-steinwold/episodes/Nate-and-Carolin---Tokenizing-The-World-With-Mintbase---ep-28-eg45jp',
    img: '/home/mintbase_have_a_zima_podcast.jpg',
  },
  {
    id: 'press-1',
    path: 'https://youtu.be/RXyKbMoVVr8?t=1489',
    img: '/home/press_cnbc.png',
  },
  {
    id: 'press-4',
    path:
      'https://anchor.fm/ma-fer5/episodes/Special-Interview-Nate-Geier--Carolin-Wend-from-Mintbase-io-eakh1r',
    img: '/home/mintbase_have_a_drink_podcast.jpg',
  },
  {
    id: 'press-2',
    path:
      'https://thedefiant.substack.com/p/he-moved-to-berlin-found-love-and?token=eyJ1c2VyX2lkIjoyOTk3NDYyLCJwb3N0X2lkIjo4NTY3MiwiXyI6Ik1CRWhFIiwiaWF0IjoxNTYzMTk0ODA5LCJleHAiOjE1NjMxOTg0MDksImlzcyI6InB1Yi0xMTI1OSIsInN1YiI6InBvc3QtcmVhY3Rpb24ifQ.UkIkevE2KOeqbpJjt63NQse10mwZFeMfCSaaUEKv4ds&utm_source=substack&utm_medium=email&utm_content=share&fbclid=IwAR3_rTg6hYE6c_K8XWNFc9XneyqI_nDMa988gb0aoUYRxBnM26--CMNlYmE',
    img: '/home/thedefi.png',
  },
  {
    id: 'press-3',
    path:
      'https://anchor.fm/wizardofdapps/episodes/Episode-16-Mintbase-with-Nate-Geier-e4s5o3',
    img: '/home/wizard.png',
  },
]

const mainChannels = [
  {
    Icon: StoreMallDirectory,
    description: 'Your Smart Contract',
    url: paths.CONTRACT,
    buttonText: 'Decentralized API',
  },
  {
    Icon: CallSplit,
    description: 'Split Royalties + Revenues',
    url: paths.ROYALTIES,
    buttonText: 'Learn More',
  },
  {
    Icon: Code,
    description: 'Build your Own Mintbase',
    url: paths.DEVELOPERS,
    buttonText: 'Code',
    isInternal: true,
  },
  {
    Icon: NaturePeople,
    description: 'Join Our Community',
    url: paths.COMMUNITY,
    buttonText: 'Learn More',
    isInternal: true,
  },
]

export const Home = () => {
  // const { data, error } = useSWR(
  //   // @ts-ignore
  //   user ? ['/api/getFood', user.token] : null,
  //   fetcher
  // )
  const { t } = useTranslation('common')

  const mainChannels = [
    {
      Icon: StoreMallDirectory,
      description: t`your-contract`,
      url: paths.CONTRACT,
      buttonText: 'Decentralized API',
    },
    {
      Icon: CallSplit,
      description: t`split-roy-rev`,
      url: paths.ROYALTIES,
      buttonText: 'Learn More',
    },
    {
      Icon: Code,
      description: t`build-your-own-mintbase`,
      url: paths.DEVELOPERS,
      buttonText: 'Code',
      isInternal: true,
    },
    {
      Icon: NaturePeople,
      description: t`join-community`,
      url: paths.COMMUNITY,
      buttonText: 'Learn More',
      isInternal: true,
    },
  ]

  const store = {
    Icon: StoreMallDirectory,
    text: t`your-store`,
    small: t`add-minters`,
    path: paths.CONTRACT,
    buttonText: t`get-started`,
    isDark: true,
  }

  const data = {
    Icon: Arweave,
    text: t`no-change-data`,
    small: t`not-us`,
    path: paths.DATA,
    buttonText: t`learn-more`,
    isDark: false,
  }

  const code = {
    Icon: Tools,
    text: 'Anything we do on Mintbase, you can do with code',
    small: 'Mint, Transfer, Purchase, Get Owner...',
    path: paths.DEVELOPERS,
    buttonText: 'Get Started',
    isDark: true,
  }

  return (
    <GoogleAnalytics>
      <Page>
        <Head>
          <title>Mintbase - NFT Factory</title>
          <meta property="twitter:site" content="@mintbase" />
          <meta property="twitter:title" content="Mintbase" />
          <meta
            property="twitter:description"
            content="Mint a blockchain asset backed by you"
          />
          <meta property="twitter:card" content="summary_large_image" />
          <meta
            property="twitter:image"
            content="https://near.mintbase.io/header/mintbase-home-card.jpg"
          />
          <meta property="twitter:url" content="https://mintbase.io" />
          <meta property="og:title" content="Mintbase" />
          <meta
            property="og:description"
            content="Mint a blockchain asset backed by you"
          />
          <meta
            property="og:image"
            content="https://near.mintbase.io/header/mintbase-home-card.jpg"
          />
          <meta property="og:url" content="https://mintbase.io" />
        </Head>
        <Main>
          <Hero />
        </Main>
        <Bar>
          <HeadRow>
            <Info color={styles.DARK2}>
              {`${t`split-revenues`} / ${t`royalties`} / ${t`collaboration`}`}
            </Info>
            <SocialContainer>
              <SocialButtons color={styles.DARK2} />
            </SocialContainer>
          </HeadRow>
        </Bar>
        <Bar>
          <FeaturedMarkets
            isAuction
            title={t`active-auctions`}
            query={GET_TOP_AUCTIONS}
          />
        </Bar>
        <Bar>
          <FeaturedMarkets
            isAuction={false}
            title={t`neat-stores`}
            query={GET_TOP_STORES}
          />
        </Bar>
        <Bar>
          <Link href={paths.MARKET}>
            <Button>{t`explore-market`}</Button>
          </Link>
        </Bar>
        <FunFacts facts={costs} title={t`tiny-transactions`} />
        <FunFacts facts={speed} title={t`fast-decentralized`} />
        <BigQuote {...store} />

        <SplitsInfo />
        <BigQuote {...data} />

        <BigQuote {...code} />

        <Boxes>
          {mainChannels.map((channel, i) => (
            <LinkBox {...channel} key={`home-${i}`} />
          ))}
        </Boxes>

        <Footer />
      </Page>
    </GoogleAnalytics>
  )
}

export default Home
