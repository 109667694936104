import styled from 'styled-components'
import * as styles from 'styles'

const Container = styled.div<{ width: number }>`
  height: ${({ width }) => (width ? `${width}px` : '40px')};
  width: ${({ width }) => (width ? `${width}px` : '40px')};
`

const Path = styled.path<{ color?: string }>`
  fill: ${({ theme }) => theme.DARK2};
`
const defaultProps = {
  width: 100,
  color: styles.OFF_WHITE,
}

const Arlogo = ({
  color,
  width,
}: { color?: string; width?: number } = defaultProps) => {
  return (
    <Container width={width}>
      <svg
        id="Layer_1"
        data-name="Layer 1"
        height="100%"
        preserveAspectRatio="xMinYMin slice"
        width="100%"
        viewBox="0 0 140 140"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <Path
            d="M76.82,90A10.48,10.48,0,0,1,76,87.9a24.07,24.07,0,0,1-.55-2.52,17.15,17.15,0,0,1-2.43,2.15,16.77,16.77,0,0,1-3,1.77,18.07,18.07,0,0,1-3.74,1.18,20.52,20.52,0,0,1-4.35.44,21.62,21.62,0,0,1-7-1.1,16.54,16.54,0,0,1-5.47-3.08,13.75,13.75,0,0,1-4.86-10.63q0-7.83,5.84-12.11t17.44-4.29H75V56.79a7.21,7.21,0,0,0-2.34-5.66c-1.55-1.39-3.8-2.08-6.73-2.08-2.6,0-4.49.55-5.68,1.67a5.78,5.78,0,0,0-1.79,4.47H45.75a13.59,13.59,0,0,1,1.4-6,14.66,14.66,0,0,1,4.08-5,20.39,20.39,0,0,1,6.55-3.41,28.35,28.35,0,0,1,8.86-1.3A30.74,30.74,0,0,1,75,40.58a20,20,0,0,1,6.71,3.26,15,15,0,0,1,4.49,5.46,17.09,17.09,0,0,1,1.61,7.58V78.14a36.48,36.48,0,0,0,.5,6.6,16.89,16.89,0,0,0,1.47,4.49V90ZM64.67,81.07a13.22,13.22,0,0,0,3.54-.45,14.14,14.14,0,0,0,3-1.2,10.19,10.19,0,0,0,2.29-1.67A8.65,8.65,0,0,0,75,75.85V67.33H68.52a19.75,19.75,0,0,0-5.08.57A9.71,9.71,0,0,0,60,69.48,6.53,6.53,0,0,0,58,72a7.71,7.71,0,0,0-.64,3.14,5.58,5.58,0,0,0,1.85,4.28A7.7,7.7,0,0,0,64.67,81.07Z"
            transform="translate(0 0)"
          />
          <Path
            d="M66.53,133.06a66.53,66.53,0,1,1,66.53-66.53A66.61,66.61,0,0,1,66.53,133.06Zm0-123.4a56.88,56.88,0,1,0,56.88,56.87A56.93,56.93,0,0,0,66.53,9.66Z"
            transform="translate(0 0)"
          />
        </g>
      </svg>
    </Container>
  )
}

export default Arlogo
