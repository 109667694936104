import React, { FC } from 'react'
import styled from 'styled-components'
import * as styles from 'styles'
import { Copy } from 'components/Typography'
import { useApp } from 'components/AppContext'
import Link from 'next/link'

const IconHolder = styled.div`
  padding-bottom: ${styles.PADDING_MD};
`

const Flag = styled.div`
  display: flex;
  padding: ${styles.PADDING_SM};
  border-bottom: 1px solid ${({ theme }) => theme.DARK2};
`

const Box = styled.div`
  display: flex;
  text-decoration: none;
  padding-top: ${styles.PADDING_LG};
  padding-bottom: ${styles.PADDING_LG};
  margin: ${styles.PADDING_SM};
  background-color: ${({ theme }) => theme.DARKER};
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  flex: 1 1 20%;
  border: 1px solid ${({ theme }) => theme.DARKER};
  &:hover {
    /* background-color: ${({ theme }) => theme.DARK3}; */
    border: 1px solid ${({ theme }) => theme.GREEN};
    color: ${({ theme }) => theme.ALWAYS_WHITE};
    cursor: pointer;
  }
`

const LinkBox = ({
  url,
  description,
  Icon,
  buttonText,
}: {
  url: string
  description: string
  Icon: FC<any>
  buttonText
}) => {
  const { colors } = useApp()
  return (
    <Link href={url}>
      <Box>
        <IconHolder>
          <Icon width="4em" color={colors.DARK1} />
        </IconHolder>

        {/* <Header1 top={styles.PADDING_SM}>{name}</Header1> */}
        <Copy bottom={styles.PADDING_MD} color={colors.DARK2}>
          {description}
        </Copy>
        <Flag>
          <Copy bottom="0" color={colors.DARK1} size="1em">
            {buttonText}
          </Copy>
        </Flag>
      </Box>
    </Link>
  )
}

export default LinkBox
