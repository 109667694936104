import React, { FC } from 'react'
import styled from 'styled-components'
import * as styles from 'styles'
import { Copy, Header1, HeaderThin } from 'components/Typography'
import { useApp } from 'components/AppContext'
import { Fact } from 'content/facts'

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  padding: ${styles.PADDING_LG};
  /* margin: ${styles.PADDING_LG}; */
  flex-direction: column;
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    padding: ${styles.PADDING_LG};
    padding-left: ${styles.PADDING_XXS};
    padding-right: ${styles.PADDING_XXS};
  }
`

const Boxes = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  flex-wrap: wrap;
`
const Flag = styled.div`
  display: flex;
  justify-content: center;
  font-size: 1em;
`

const MainCopy = styled(HeaderThin)`
  font-size: 3em;
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    font-size: 1.3em;
  }
`

const Box = styled.a`
  display: flex;
  flex-grow: 1;
  text-decoration: none;
  padding: ${styles.PADDING_LG};
  margin: ${styles.PADDING_SM};
  flex-direction: column;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.DARK};
  /* flex: 1 1 25%; */
  &:hover {
    border: 1px solid ${({ theme }) => theme.GREEN};
    color: ${({ theme }) => theme.ALWAYS_WHITE};
    cursor: pointer;
  }

  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    padding: ${styles.PADDING_SM};
    margin: ${styles.PADDING_SM};
  }
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`
const Header = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  font-size: 2.4rem;
  padding: ${styles.PADDING_MD};
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    font-size: 1rem;
  }
`

const BoxTitle = styled.div`
  display: flex;
  flex-grow: 1;
  margin-bottom: ${styles.PADDING_MD};
  /* width: 100%; */
  justify-content: center;
  font-size: 0.9rem;
  border-bottom: 1px solid ${({ theme }) => theme.DARK2};
`

const FunFacts = ({ facts, title }: { facts: Fact[]; title: string }) => {
  const { colors } = useApp()
  return (
    <Container>
      <Header>
        <Header1 bottom={styles.PADDING_MD} color={colors.DARK2}>
          {title}
        </Header1>
      </Header>
      <Boxes>
        {facts.map(({ loud, path, main, small, type }, i) => (
          <Box href={path} target="_blank" key={`type-${i}`}>
            <Content>
              <BoxTitle>
                <HeaderThin bottom={styles.PADDING_MD} color={colors.DARK1}>
                  {main}
                </HeaderThin>
              </BoxTitle>

              <MainCopy color={colors.OFF_WHITE}>{loud}</MainCopy>
              {small && (
                <Flag>
                  <Copy bottom="0" color={colors.DARK2}>
                    {small}
                  </Copy>
                </Flag>
              )}
            </Content>
          </Box>
        ))}
      </Boxes>
    </Container>
  )
}

export default FunFacts
