import { Copy, HeaderThin } from 'components/Typography'
import styled from 'styled-components'
import * as styles from 'styles'
import { useApp } from './AppContext'
import { Button } from './Buttons'
import Link from 'next/link'

const Container = styled.div<{ isDark: boolean }>`
  display: flex;
  justify-content: center;
  padding: ${styles.PADDING_LG};
  align-items: center;
  background-color: ${({ isDark, theme }) =>
    isDark ? theme.DARK1 : theme.DARKER};
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    flex-direction: column;
    padding-left: ${styles.PADDING_XXS};
    padding-right: ${styles.PADDING_XXS};
    padding: ${styles.PADDING_LG};
  }

  /* margin: ${styles.PADDING_LG}; */
  /* flex-direction: column; */
`

const ImageHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${styles.PADDING_LG};
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    padding: ${styles.PADDING_SM};
  }
  /* margin: ${styles.PADDING_LG}; */
`

const Header = styled.div`
  display: flex;
  padding: ${styles.PADDING_XL};
  flex-direction: column;
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    padding: ${styles.PADDING_SM};
    text-align: center;
    justify-content: center;
    align-items: center;
  }
`

const ExtraButton = styled(Button)`
  display: inline-block;
  max-width: 200px;
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    padding: ${styles.PADDING_MD};
  }
`

const BigQuote = ({ Icon, text, small, path, buttonText, isDark }) => {
  const { colors } = useApp()
  const thisColor = isDark ? colors.DARK3 : colors.DARK1
  return (
    <Container isDark={isDark}>
      <ImageHolder>
        <Icon color={thisColor} width={90} />
      </ImageHolder>

      <Header>
        <HeaderThin size="3rem" color={thisColor}>
          {text}
        </HeaderThin>
        <Copy color={thisColor} size="1.5rem" bottom={styles.PADDING_MD}>
          {small}
        </Copy>
        <Link href={path}>
          <ExtraButton>{buttonText}</ExtraButton>
        </Link>
      </Header>
    </Container>
  )
}

export default BigQuote
