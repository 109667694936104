import React, { useEffect } from 'react'
import Router from 'next/router'
import { initGA, logPageView } from '../utils/analytics'
import styled from 'styled-components'

const Container = styled.div``

const GoogleAnalytics = (props) => {
  if (typeof window !== undefined) {
    useEffect(() => {
      if (!window.GA_INITIALIZED) {
        initGA()
        logPageView()
        window.GA_INITIALIZED = true
      }
      Router.events.on('routeChangeComplete', logPageView)
    }, [])
  }

  return <Container>{props.children}</Container>
}

export default GoogleAnalytics
