import Arweave from './Arlogo'
import { Copy, List, Header1, HeaderThin } from 'components/Typography'
import styled from 'styled-components'
import * as styles from 'styles'
import { useApp } from './AppContext'
import * as paths from 'content/paths'
import { BlankA, Button } from './Buttons'
import NextImage from 'next/image'
import Link from 'next/link'

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: ${styles.PADDING_LG};
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    padding: ${styles.PADDING_LG};
    padding-left: ${styles.PADDING_XS};
    padding-right: ${styles.PADDING_XS};
  }
`

const ImageHolder = styled.div`
  display: flex;
  align-items: center;
  padding: ${styles.PADDING_LG};
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    padding: ${styles.PADDING_SM};
  }
`

const Header = styled.div`
  display: flex;
  /* background-color: ${({ theme }) => theme.DARK4}; */
  padding: ${styles.PADDING_XL};
  /* justify-content: center; */
  max-width: 800px;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    padding: ${styles.PADDING_LG};
    padding-left: ${styles.PADDING_XS};
    padding-right: ${styles.PADDING_XS};
  }
`

const ExtraButton = styled(Button)`
  display: inline-block;
  max-width: 200px;
  @media (max-width: ${styles.SCREEN_MD_RAW}px) {
    margin-top: ${styles.PADDING_SM};
    padding: ${styles.PADDING_MD};
  }
`

const SplitsInfo = () => {
  const { colors } = useApp()
  return (
    <Container>
      <Header>
        <HeaderThin size="3em">Royalties + Rev Share + Auctions</HeaderThin>
        <Copy top={styles.PADDING_MD} color={colors.DARK2} size="1.5rem">
          +25 Royalty or Single Split payouts on a single NFT purchase
        </Copy>
        <ImageHolder>
          <NextImage src="/home/splits.png" width={1082} height={99} />
        </ImageHolder>

        <Link href={paths.ROYALTIES}>
          <ExtraButton>Go Deeper</ExtraButton>
        </Link>
      </Header>
    </Container>
  )
}

export default SplitsInfo
