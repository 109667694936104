import Big from 'big.js'
import math from 'mathjs'

export const bigToNear = (value = '0', to = 2) =>
  Big(value)
    .div(10 ** 24)
    .toFixed(to === 0 ? undefined : to)

export const parseYactoToNear = (price: number) => {
  return price / 1e24
}

export const parseSciToString = (price: number) => {
  return price.toLocaleString('en-US').replace(/,/g, '')
}
